import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import {
  getFilterParams,
  setFilterParams,
  getSelectedCategory,
  getSelectedClass,
  getSelectedSubClass,
  setSelectedCategory,
  setSelectedClass,
  setSelectedSubClass,
  setCurrentPage,
} from "../../store/slices/work-with-data/filterSlice";
import { setSelectedEquipmentTagId } from "../../store/slices/work-with-data/characteristicsSlice";
import {
  displayCellLabel,
  displayCriticalily,
  getFormattedDate,
  getStatusSeverity,
} from "../../utils";

import { StatusAlert } from "../common/StatusAlert";
import { TableLoader } from "../common/TableLoader";
import { NoDataFoundInTable } from "../common/NoDataFoundInTable";
import { WorkWithDataHeader } from ".";

import { TABLE_COLUMN_LABELS } from "../../constants/work-with-data";

export const WorkWithDataTable = ({
  rows,
  columns,
  loading,
  totalRows,
  rowsPerPage,
  currentPage,
  setRowsPerPage,
  toggleCategoriesDrawer,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedCategory = useSelector(getSelectedCategory);
  const selectedClass = useSelector(getSelectedClass);
  const selectedSubClass = useSelector(getSelectedSubClass);

  const filterParams = useSelector(getFilterParams);

  const handleClick = (row) => {
    dispatch(setSelectedEquipmentTagId(row.equipmenT_TAG));
    navigate("/work-with-data/" + row.equipmenT_TAG);
  };

  const handlePageChange = (_event, page) => {
    dispatch(setFilterParams({ ...filterParams, StartFrom: page }));
    dispatch(setCurrentPage(page));
  };

  const handleRowsPerPageChange = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    dispatch(setCurrentPage(0));
    setRowsPerPage(rowsPerPage);
    dispatch(setFilterParams({ ...filterParams, FetchRecord: rowsPerPage }));
    sessionStorage.setItem("wwdRowsPerPage", rowsPerPage);
  };

  const displayTableHeader = (col) => {
    switch (col.id) {
      case "Description":
      case "manufacturerAndSerialNumber":
      case "equipmentAndParentTag":
        return (
          <TableCell
            key={col.id}
            colSpan={2}
            width={col.width}
            sx={{
              display: "grid",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: col.align,
            }}
          >
            {col.label}
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={col.id}
            width={col.width}
            sx={{
              display: "grid",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: col.align,
            }}
          >
            {col.label}
          </TableCell>
        );
    }
  };

  const displayTableCell = (col, row, index) => {
    switch (col.id) {
      case "equipmentAndParentTag":
        return (
          <TableCell
            colSpan={2}
            key={col.id}
            align={col.align}
            width={col.width}
            sx={{
              display: "grid",
              flexGrow: 1,
              alignItems: "center",
              minWidth: 180,
            }}
          >
            <div className="flex flex-col">
              <span className="font-bold font-roboto">
                {displayCellLabel(row.equipmenT_TAG)}
              </span>
              <span className="text-black/[.54]">
                {displayCellLabel(row.parenT_EQUIPMENT_TAG)}
              </span>
            </div>
          </TableCell>
        );
      case "equipmenT_TAG_DESCRIPTION":
        return (
          <TableCell
            colSpan={2}
            key={col.id}
            align={col.align}
            width={col.width}
            sx={{
              display: "grid",
              flexGrow: 1,
              alignItems: "center",
              minWidth: 180,
            }}
          >
            <div className="flex flex-col">
              <span>{displayCellLabel(row.equipmenT_TAG_DESCRIPTION)}</span>
            </div>
          </TableCell>
        );
      case "equipmenT_TAG_DESCRIPTION_RU":
        return (
          <TableCell
            colSpan={2}
            key={col.id}
            align={col.align}
            width={col.width}
            sx={{
              display: "grid",
              flexGrow: 1,
              alignItems: "center",
              minWidth: 180,
            }}
          >
            <div className="flex flex-col">
              <span>{displayCellLabel(row.equipmenT_TAG_DESCRIPTION_RU)}</span>
            </div>
          </TableCell>
        );
      case "manufacturerAndSerialNumber":
        return (
          <TableCell
            colSpan={2}
            key={col.id}
            align={col.align}
            width={col.width}
            sx={{
              display: "grid",
              flexGrow: 1,
              alignItems: "center",
              minWidth: 180,
            }}
          >
            <div className="flex flex-col">
              <span>{displayCellLabel(row.manufacturer)}</span>
              <span className="text-black/[.54]">
                {displayCellLabel(row.seriaL_NUMBER)}
              </span>
            </div>
          </TableCell>
        );
      // case "starT_UP_DATE":
      //   return (
      //     <TableCell
      //       key={col.id}
      //       align={col.align}
      //       width={col.width}
      //       sx={{ display: "grid", flexGrow: 1, alignItems: "center" }}
      //     >
      //       {getFormattedDate(row[col.id])}
      //     </TableCell>
      //   );
      case "aC_RANKING":
        return (
          <TableCell
            key={col.id}
            align={col.align}
            width={col.width}
            sx={{ display: "grid", flexGrow: 1, alignItems: "center" }}
          >
            {displayCriticalily(row[col.id])}
          </TableCell>
        );
      // case "comment":
      //   return (
      //     <TableCell
      //       key={col.id}
      //       align={col.align}
      //       width={col.width}
      //       sx={{
      //         display: "grid",
      //         flexGrow: 1,
      //         alignItems: "center",
      //         wordBreak: "break-all",
      //       }}
      //     >
      //       {row[col.id] === null ? "No comment" : row[col.id]}
      //     </TableCell>
      //   );
      // case "dcaM_STATUS_NAME":
      //   return (
      //     <TableCell
      //       key={col.id}
      //       align={col.align}
      //       width={col.width}
      //       sx={{ display: "grid", flexGrow: 1, alignItems: "center" }}
      //     >
      //       <StatusAlert
      //         value={row[col.id]}
      //         severity={getStatusSeverity(row[col.id])}
      //       />
      //     </TableCell>
      //   );
      case "details":
        return (
          <TableCell
            key={col.id}
            align={col.align}
            width={col.width}
            sx={{ display: "grid", flexGrow: 1, alignItems: "center" }}
          >
            <Button
              data-testid={`view_more_btn_${index}`}
              onClick={() => handleClick(row)}
            >
              View More
            </Button>
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={col.id}
            align={col.align}
            width={col.width}
            sx={{
              display: "grid",
              flexGrow: 1,
              alignItems: "center",
              wordBreak: "break-all",
            }}
          >
            {displayCellLabel(row[col.id])}
          </TableCell>
        );
    }
  };

  const handleRemoveFilter = (value = {}) => {
    let searchIncluded = "";
    if (filterParams.Search) {
      const search = {
        ColumnName: "equipmenT_TAG",
        Operator: "Contains",
        Value: filterParams.Search,
      };
      searchIncluded = filterParams.FilteredColums.find(
        (filterValue) => JSON.stringify(filterValue) === JSON.stringify(search)
      );
    }
    const filteredColums = filterParams.FilteredColums.filter(
      (item) => JSON.stringify(item) !== JSON.stringify(value)
    );
    dispatch(
      setFilterParams({
        ...filterParams,
        Search: searchIncluded ? undefined : filterParams.Search,
        FilteredColums: filteredColums,
      })
    );
    dispatch(setCurrentPage(0));
  };

  const handleDeleteSelectedCategory = () => {
    dispatch(
      setFilterParams({
        ...filterParams,
        CategoryId: undefined,
        ClassId: undefined,
        SubClassId: undefined,
      })
    );
    dispatch(setSelectedCategory(undefined));
    dispatch(setSelectedClass(undefined));
    dispatch(setSelectedSubClass(undefined));
  };

  const displaySelectedCategory = () => {
    const label = selectedCategory.label;
    if (label) {
      return (
        <Chip
          label={label}
          color="primary"
          size="small"
          variant="outlined"
          onDelete={handleDeleteSelectedCategory}
        />
      );
    }
  };

  const handleDeleteSelectedClass = () => {
    dispatch(
      setFilterParams({
        ...filterParams,
        ClassId: undefined,
        SubClassId: undefined,
      })
    );
    dispatch(setSelectedClass(undefined));
    dispatch(setSelectedSubClass(undefined));
  };

  const displaySelectedClass = () => {
    const label = selectedClass.label;
    if (label) {
      return (
        <Chip
          label={label}
          color="primary"
          size="small"
          variant="outlined"
          onDelete={handleDeleteSelectedClass}
        />
      );
    }
  };

  const handleDeleteSelectedSubClass = () => {
    dispatch(
      setFilterParams({
        ...filterParams,
        SubClassId: undefined,
      })
    );
    dispatch(setSelectedSubClass(undefined));
  };

  const displaySelectedSubClass = () => {
    const label = selectedSubClass.label;
    if (label) {
      return (
        <Chip
          label={label}
          color="primary"
          size="small"
          variant="outlined"
          onDelete={handleDeleteSelectedSubClass}
        />
      );
    }
  };

  const displayCategoriesGroup = () => {
    return !displaySelectedClass() && !displaySelectedSubClass() ? (
      displaySelectedCategory()
    ) : (
      <div className="flex p-[4px] rounded-3xl border border-dashed border-blue-400 gap-4">
        {displaySelectedCategory()}
        {displaySelectedClass()}
        {displaySelectedSubClass()}
      </div>
    );
  };

  const handleDeleteAreaCode = () => {
    dispatch(
      setFilterParams({
        ...filterParams,
        AreaCode: { label: "", value: "" },
        UnitCode: { label: "", value: "" },
      })
    );
  };

  const displayArea = () => {
    if (filterParams.AreaCode.label) {
      return (
        <Chip
          label={filterParams.AreaCode.label}
          color="primary"
          size="small"
          variant="outlined"
          onDelete={handleDeleteAreaCode}
        />
      );
    }
  };

  const handleDeleteUnitCode = () => {
    dispatch(
      setFilterParams({
        ...filterParams,
        UnitCode: { label: "", value: "" },
      })
    );
  };

  const displayUnit = () => {
    if (filterParams.UnitCode.label) {
      return (
        <Chip
          label={filterParams.UnitCode.label}
          color="primary"
          size="small"
          variant="outlined"
          onDelete={handleDeleteUnitCode}
        />
      );
    }
  };

  const displayAreaAndUnitGroup = () => {
    return !displayUnit() ? (
      displayArea()
    ) : (
      <div className="flex p-[4px] rounded-3xl border border-dashed border-blue-400 gap-4">
        {displayArea()}
        {displayUnit()}
      </div>
    );
  };

  const parseFilteredColums = (filters) => {
    const filterChips = filters.map((item) => {
      const { ColumnName, Operator, Value } = item;
      const valueLabel =
        Value === "" ? "Empty" : Value === "." ? "Blank" : Value;
      return {
        label: `${TABLE_COLUMN_LABELS[ColumnName]}  ${Operator}  ${valueLabel}`,
        filterValue: { ColumnName, Operator, Value },
      };
    });
    return filterChips;
  };

  const displayFilterByColumnLabel = () => {
    if (filterParams.FilteredColums) {
      const chips = parseFilteredColums(filterParams.FilteredColums);
      return chips.map(({ label, filterValue }) => (
        <Chip
          key={label}
          label={label}
          color="primary"
          size="small"
          variant="outlined"
          onDelete={() => handleRemoveFilter(filterValue)}
        />
      ));
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{ width: "100%", mb: 2, p: 0 }}
        elevation={0}
        variant="outlined"
      >
        <WorkWithDataHeader toggleCategoriesDrawer={toggleCategoriesDrawer} />

        <Box className="flex items-center gap-4 pl-[24px] w-full h-auto flex-wrap">
          {displayFilterByColumnLabel()}
          {displayAreaAndUnitGroup()}
          {displayCategoriesGroup()}
        </Box>

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHead>
              <TableRow sx={{ display: "flex" }}>
                {columns.filter((col) => col.isChecked).map(displayTableHeader)}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableLoader colSpan={columns.length} />
            ) : (
              <>
                {!rows.length && !loading && (
                  <NoDataFoundInTable
                    label="No tags found"
                    colSpan={columns.length}
                  />
                )}
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={row.id}
                        sx={{ display: "flex" }}
                      >
                        {columns
                          .filter((col) => col.isChecked)
                          .map((col) => displayTableCell(col, row, index))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={currentPage || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          showFirstButton
          showLastButton
        />
      </Paper>
    </Box>
  );
};
