import { useDispatch } from "react-redux";
import {
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Tooltip,
  TableHead,
  Button,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";

import DateRangeIcon from "@mui/icons-material/DateRange";

import {
  debounce,
  displayCellLabel,
  displayCriticalily,
  getFormattedDate,
  getStatusSeverity,
  getUpdatedCharsState,
  getUpdatedCommentsState,
} from "../../../../../utils";
import {
  EQUIPMENT_CHARS_MAP,
  EQUIPMENT_GENERAL_CHAR_NAME,
  MANUFACTURER_STATUS,
  ONLY_JDE_EQUIPMENT_CHARS,
} from "../../../../../constants/work-with-data";

import { StatusAlert } from "../../../../common/StatusAlert";
import { Comments } from "../../../../common/Comments";
import { useState } from "react";
import { useWorkWithData } from "../../../../../hooks/useWorkWithData";
import { setIsCharacteristicsUpdated } from "../../../../../store/slices/work-with-data/characteristicsSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const AttributesEditTable = ({
  rows,
  criticalityOptions,
  plannerGroupOptions,
  costCenterOptions,
  manufacturerOptions,
  statusOptions,
  attributeStatuses,
  handleCancel,
  editMode,
  setEditMode,
  loading,
  classesAndSubClasses,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(rows);
  const [isPending, setIsPending] = useState(false);
  const [selectedClass, setSelectedClass] = useState([]);

  const [commentsState, setCommentsState] = useState(
    rows.map(
      (row) => row.comments.find((comment) => comment.current)?.comment || ""
    )
  );

  const {
    updateWalkdownEquipmentTagCharacteristics,
    addNewEquipTagWalkdownCharComment,
  } = useWorkWithData();

  useEffect(() => {
    const initialClassRow = rows.find(
      (row) => row.jdeAttrName === "JDE_CLASSIFICATION"
    );
    if (initialClassRow?.walkdownValue) {
      const matchingClass = classesAndSubClasses.filter(
        (item) => item.class === initialClassRow.walkdownValue
      );
      setSelectedClass(matchingClass);
    }
  }, [rows, classesAndSubClasses]);

  const displayClassValueWithDescription = (classValue) => {
    const matchedClass = classesAndSubClasses.find(
      (item) => item.class === classValue
    );
    if (matchedClass) {
      return `${matchedClass.class} - ${matchedClass.description}`;
    }
    return classValue;
  };

  const displaySubClassValueWithDescription = (subClassValue, attrType) => {
    const initialClass = rows.find(
      (row) => row.jdeAttrName === "JDE_CLASSIFICATION"
    );
    const matchedSubClass = classesAndSubClasses.find(
      (item) => item.class === initialClass[attrType]
    );
    if (matchedSubClass) {
      const initialSubClass =
        matchedSubClass.subClasses.length &&
        matchedSubClass.subClasses.find(
          (item) => item.subClass === subClassValue
        );
      if (initialSubClass) {
        return `${initialSubClass.subClass} - ${initialSubClass.description}`;
      }
    }
    return subClassValue;
  };

  const displayJDEValue = (row) => {
    const { jdeAttrName, jdeValue } = row;

    let manufacturerInfo;
    if (jdeAttrName === EQUIPMENT_GENERAL_CHAR_NAME.MANUFACTURER) {
      manufacturerInfo = manufacturerOptions.find(
        ({ value }) => value === jdeValue
      );
    }

    switch (jdeAttrName) {
      case EQUIPMENT_GENERAL_CHAR_NAME.ACQN_VALUE_DATE:
      case EQUIPMENT_GENERAL_CHAR_NAME.START_UP_DATE:
        return (
          <div className="flex items-center gap-2">
            <DateRangeIcon className="text-black/[0.54]" />
            <span className="text-sm leading-4 text-black/[0.87] font-roboto ">
              {displayCellLabel(getFormattedDate(jdeValue))}
            </span>
          </div>
        );
      case EQUIPMENT_GENERAL_CHAR_NAME.AC_RANKING:
        return displayCriticalily(jdeValue);
      case EQUIPMENT_GENERAL_CHAR_NAME.JDE_CLASSIFICATION:
        return displayClassValueWithDescription(jdeValue);
      case EQUIPMENT_GENERAL_CHAR_NAME.JDE_SUBCLASSIFICATION:
        return displaySubClassValueWithDescription(jdeValue, "jdeValue");
      default:
        return jdeAttrName === EQUIPMENT_GENERAL_CHAR_NAME.MANUFACTURER &&
          manufacturerInfo?.status === MANUFACTURER_STATUS.NEW ? (
          <div className="flex items-center gap-2 cursor-pointer bg-[#C1E1C1]/[0.5] rounded p-1">
            <Tooltip placement="top" title="Manufacturer is not approved yet">
              <span className="text-sm leading-4 text-black/[0.87] font-roboto ">
                {displayCellLabel(jdeValue)}
              </span>
            </Tooltip>
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <span className="text-sm leading-4 text-black/[0.87] font-roboto ">
              {displayCellLabel(jdeValue)}
            </span>
          </div>
        );
    }
  };

  const displayMESValue = (row) => {
    const { jdeAttrName, walkdownValue } = row;

    switch (jdeAttrName) {
      case EQUIPMENT_GENERAL_CHAR_NAME.JDE_CLASSIFICATION:
        return displayClassValueWithDescription(walkdownValue);
      case EQUIPMENT_GENERAL_CHAR_NAME.JDE_SUBCLASSIFICATION:
        return displaySubClassValueWithDescription(
          walkdownValue,
          "walkdownValue"
        );
      default:
        return displayCellLabel(walkdownValue);
    }
  };

  const handleRowStatusChange = (_event, option, jdeAttrName) => {
    if (option) {
      const newState = state.map((item) =>
        item.walkdownAttrStatusName !== option.label &&
        item.jdeAttrName === jdeAttrName
          ? {
              ...item,
              walkdownAttrStatusCode: option.value,
              walkdownAttrStatusName: option.label,
            }
          : item
      );
      setState(newState);
    }
  };

  const displayAtributeStatus = (row) => {
    if (ONLY_JDE_EQUIPMENT_CHARS.includes(row.jdeAttrName)) {
      return (
        <StatusAlert
          value={row.jdeAttrStatusName}
          severity={getStatusSeverity(row.jdeAttrStatusName)}
        />
      );
    }

    return (
      <>
        {editMode ? (
          <Autocomplete
            size="small"
            value={row.walkdownAttrStatusName}
            disablePortal
            options={attributeStatuses}
            renderInput={(params) => <TextField size="small" {...params} />}
            onChange={(event, option) =>
              handleRowStatusChange(event, option, row.jdeAttrName)
            }
            disabled={isPending || loading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disableClearable
          />
        ) : (
          <StatusAlert
            value={row.walkdownAttrStatusName}
            severity={getStatusSeverity(row.walkdownAttrStatusName)}
          />
        )}
      </>
    );
  };

  const handleCommentChange = debounce((rowIndex, newComment) => {
    const updatedCommentsState = [...commentsState];
    updatedCommentsState[rowIndex] = newComment;
    setCommentsState(updatedCommentsState);
  }, 500);

  const handleSave = () => {
    const classRow = state.find(
      (row) => row.jdeAttrName === "JDE_CLASSIFICATION"
    );
    const subclassRow = state.find(
      (row) => row.jdeAttrName === "JDE_SUBCLASSIFICATION"
    );

    if (classRow?.walkdownValue && !subclassRow?.walkdownValue) {
      toast.error("Sub-class is required when Class is selected");
      return;
    }

    const updatedAttr = getUpdatedCharsState(rows, state, attributeStatuses);
    const updatedComments = getUpdatedCommentsState(commentsState, state);

    const statusOnlyChanges = updatedComments
      .map((commentsUpdate) => {
        const { commentHistoryPhk, commentHistoryBk } = commentsUpdate;

        const row = state.find((r) => r.walkdownAttrCode === commentHistoryPhk);
        const initialRow = rows.find(
          (r) => r.walkdownAttrCode === commentHistoryPhk
        );

        if (!row || !initialRow) return null;

        const initialStatus = initialRow.walkdownAttrStatusName;
        const updatedStatus = row.walkdownAttrStatusName;

        const commentUpdate = updatedComments.find(
          (c) => c.commentHistoryPhk === commentHistoryPhk
        );
        const newComment = commentUpdate ? commentUpdate.comment : "";

        let commentMessage = "";
        if (initialStatus !== updatedStatus) {
          if (newComment.trim().length) {
            commentMessage = `Changed status from ${initialStatus} to ${updatedStatus} by leaving a comment: ${newComment}`;
          } else {
            commentMessage = `Changed status from ${initialStatus} to ${updatedStatus}`;
          }
        } else if (newComment) {
          commentMessage = newComment;
        }
        return commentMessage.trim().length
          ? {
              commentHistoryPhk: commentHistoryPhk,
              commentHistoryBk: commentHistoryBk,
              comment: commentMessage,
            }
          : null;
      })
      .filter(Boolean);

    if (!updatedAttr.length && !statusOnlyChanges.length) {
      setEditMode(false);
      return;
    }
    const requests = [];
    if (updatedAttr.length > 0) {
      requests.push(updateWalkdownEquipmentTagCharacteristics(updatedAttr));
    }
    if (statusOnlyChanges.length > 0) {
      requests.push(addNewEquipTagWalkdownCharComment(statusOnlyChanges));
    }
    setIsPending(updatedAttr.length > 0 || statusOnlyChanges.length > 0);
    Promise.all(requests)
      .then((responses) => {
        const data = responses.some((response) => response.status === 200);
        if (data) {
          dispatch(setIsCharacteristicsUpdated());
        }
      })
      .finally(() => {
        setIsPending(false);
        setEditMode(false);
      });
  };

  const handleJDEStatusValueChange = (_event, option, characteristicName) => {
    if (option) {
      const newState = state.map((item) =>
        item.jdeAttrName === characteristicName &&
        item.walkdownValue !== option.value
          ? { ...item, walkdownValue: option.value }
          : item
      );
      setState(newState);
    }
  };

  const handleJDEClassValueChange = (_event, option, characteristicName) => {
    setSelectedClass(
      classesAndSubClasses.filter((item) => item.class === option.value)
    );
    if (option) {
      const newState = state.map((item) => {
        if (item.jdeAttrName === "JDE_SUBCLASSIFICATION") {
          return { ...item, walkdownValue: "" };
        }
        if (
          item.jdeAttrName === characteristicName &&
          item.walkdownValue !== option.value
        ) {
          return { ...item, walkdownValue: option.value };
        }
        return item;
      });
      setState(newState);
    }
  };

  const handleJDESubClassValueChange = (_event, option, characteristicName) => {
    if (option) {
      const newState = state.map((item) =>
        item.jdeAttrName === characteristicName &&
        item.walkdownValue !== option.value
          ? { ...item, walkdownValue: option.value }
          : item
      );
      setState(newState);
    }
  };

  const handleMESValueChange = (event, characteristicName) => {
    const value = event.target.value;
    const newState = state.map((item) =>
      item.jdeAttrName === characteristicName
        ? { ...item, walkdownValue: value }
        : item
    );
    setState(newState);
  };

  const renderEditableValue = (row) => {
    if (row.jdeAttrName === "JDE_STATUS") {
      return (
        <Autocomplete
          size="small"
          value={row.walkdownValue}
          disablePortal
          options={[
            { value: "AV", label: "AV" },
            { value: "DN", label: "DN" },
          ]}
          renderInput={(params) => <TextField size="small" {...params} />}
          onChange={(event, option) =>
            handleJDEStatusValueChange(event, option, row.jdeAttrName)
          }
          disabled={isPending || loading}
          disableClearable
        />
      );
    } else if (row.jdeAttrName === "JDE_CLASSIFICATION") {
      return (
        <Autocomplete
          size="small"
          value={displayMESValue(row)}
          disablePortal
          options={
            (classesAndSubClasses.length &&
              classesAndSubClasses.map((item) => ({
                value: item.class,
                label: `${item.class} - ${item.description}`,
              }))) ||
            []
          }
          renderInput={(params) => <TextField size="small" {...params} />}
          onChange={(event, option) =>
            handleJDEClassValueChange(event, option, row.jdeAttrName)
          }
          disabled={isPending || loading}
          disableClearable
        />
      );
    } else if (row.jdeAttrName === "JDE_SUBCLASSIFICATION") {
      const selectedValue =
        selectedClass.length &&
        selectedClass[0].subClasses.find(
          (item) => item.subClass === row.walkdownValue
        );

      return (
        <Autocomplete
          size="small"
          value={
            selectedValue
              ? `${selectedValue.subClass} - ${selectedValue.description}`
              : ""
          }
          disablePortal
          options={
            (selectedClass.length &&
              selectedClass[0].subClasses.map((item) => ({
                value: item.subClass,
                label: `${item.subClass} - ${item.description}`,
              }))) ||
            []
          }
          renderInput={(params) => <TextField size="small" {...params} />}
          onChange={(event, option) =>
            handleJDESubClassValueChange(event, option, row.jdeAttrName)
          }
          disabled={isPending || loading}
          disableClearable
        />
      );
    } else {
      return (
        <TextField
          variant="outlined"
          size="small"
          defaultValue={row.walkdownValue}
          disabled={loading || isPending}
          onChange={(event) => handleMESValueChange(event, row.jdeAttrName)}
        />
      );
    }
  };

  return (
    <>
      <div className="flex items-center justify-end w-full px-4 pt-4">
        <div className="flex items-center justify-end gap-4">
          {editMode && (
            <>
              <Button
                disabled={isPending || loading}
                variant="outlined"
                onClick={handleCancel}
                data-testid="cancel-btn"
              >
                <span className="text-sm">Cancel</span>
              </Button>
              <Button
                disabled={isPending || loading}
                variant="contained"
                onClick={handleSave}
                data-testid="save-btn"
              >
                {isPending || loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <span className="text-sm">Save</span>
                )}
              </Button>
            </>
          )}
        </div>
      </div>
      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="equipment overview view table"
        >
          <>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>JDE E1 Original Value</TableCell>
                <TableCell>MES Value</TableCell>
                <TableCell>Attribute status</TableCell>
                <TableCell>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.map((row, index) => (
                <TableRow
                  key={row.jdeAttrCode}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="left"
                    width={150}
                    component="th"
                    scope="row"
                  >
                    <div className="flex flex-col">
                      <span className="text-sm leading-4 text-black/[0.87] font-bold font-roboto">
                        {EQUIPMENT_CHARS_MAP[row.jdeAttrName]}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="left" width={340}>
                    <div className="flex items-center gap-2">
                      <span className="text-sm leading-4 text-black/[0.87] font-roboto ">
                        {displayJDEValue(row)}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="left" width={340}>
                    <div className="flex flex-col">
                      {!editMode ? (
                        <span className="text-sm leading-4 text-black/[0.87] font-roboto">
                          {displayMESValue(row)}
                        </span>
                      ) : !ONLY_JDE_EQUIPMENT_CHARS.includes(
                          row.jdeAttrName
                        ) ? (
                        renderEditableValue(row)
                      ) : (
                        displayMESValue(row)
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="left" width={250}>
                    {displayAtributeStatus(row)}
                  </TableCell>
                  <TableCell align="left" width={250}>
                    <Comments
                      row={row}
                      editMode={editMode}
                      loading={loading || isPending}
                      onCommentChange={(newComment) =>
                        handleCommentChange(index, newComment)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        </Table>
      </TableContainer>
    </>
  );
};
